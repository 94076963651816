import { CustomerTag } from "@farmact/model/src/model/CustomerTag";
import { Employee } from "@farmact/model/src/model/Employee";
import { GasStation } from "@farmact/model/src/model/GasStation";
import { LoadingOrUnloadingPoint } from "@farmact/model/src/model/LoadingOrUnloadingPoint";
import { Machine } from "@farmact/model/src/model/Machine";
import { Maschinenring } from "@farmact/model/src/model/Maschinenring";
import { Resource } from "@farmact/model/src/model/Resource";
import { ResourceTag } from "@farmact/model/src/model/ResourceTag";
import { Service } from "@farmact/model/src/model/services/Service";
import { Supplier } from "@farmact/model/src/model/Supplier";
import { create } from "zustand";

export type OrganizationStore = {
    gasStations: GasStation[];
    gasStationsLoading: boolean;

    maschinenrings: Maschinenring[];
    maschinenringsLoading: boolean;

    customerTags: CustomerTag[];
    customerTagsLoading: boolean;

    suppliers: Supplier[];
    suplliersLoading: boolean;

    resourceTags: ResourceTag[];
    resourceTagsLoading: boolean;

    loadingOrUnloadingPoints: LoadingOrUnloadingPoint[];
    loadingOrUnloadingPointsLoading: boolean;

    machines: Machine[];
    machinesLoading: boolean;

    employees: Employee[];
    employeesLoading: boolean;

    services: Service[];
    servicesLoading: boolean;

    resources: Resource[];
    resourcesLoading: boolean;
};

export const useOrganizationStore = create<OrganizationStore>()(() => ({
    gasStations: [],
    gasStationsLoading: true,
    maschinenrings: [],
    maschinenringsLoading: true,
    customerTags: [],
    customerTagsLoading: true,
    suppliers: [],
    suplliersLoading: true,
    resourceTags: [],
    resourceTagsLoading: true,
    loadingOrUnloadingPoints: [],
    loadingOrUnloadingPointsLoading: true,
    machines: [],
    machinesLoading: true,
    employees: [],
    employeesLoading: true,
    services: [],
    servicesLoading: true,
    resources: [],
    resourcesLoading: true,
}));
