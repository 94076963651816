import { Machine } from "@farmact/model/src/model/Machine";
import { useOrganizationStore } from "@/components/organization/stores/organizationStore";
import "./rentalOrderPriceTrackingFormTitle.scss";

type RentalOrderPriceTrackingFormTitleProps = {
    machineId: Machine["id"];
};

export const RentalOrderPriceTrackingFormTitle = (props: RentalOrderPriceTrackingFormTitleProps) => {
    const machines = useOrganizationStore(s => s.machines);

    const machine = machines.find(machine => {
        return machine.id === props.machineId;
    });

    return <h2 className="rental-order-price-tracking-form-title">{machine?.name ?? "Unbekannte Maschine"}</h2>;
};
